import gql from 'graphql-tag';

const request = {
	properties: {
		name: 'session>generateid'
	},

	validation: {},

	gql: {
		schema: gql`
			query SessionGenerateId {
			  request {
			    possessid
			  }
			}
		`
	}
};

export default request;
