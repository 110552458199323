import { useState, useEffect } from 'react'; // , { useContext }
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import { withApollo } from '../../apollo/lib';

import { isEmptyObject } from '../../helpers/common';
import useCoderegister from '../_hooks/useCoderegister';
import { useStore as loaderStore } from '../../contexts/loader';
import { useStore as appStore } from '../../contexts/app';
import request from '../../requests/query/loader';
import { setContext } from '../../helpers/gql';

// const loaded = [];

const AppLoader = ({ children, onLoad, load }) => {
	const { dispatch: loaderDispatch } = loaderStore();
	const { dispatch: appDispatch } = appStore();
	const { updateLocalCoderegisters } = useCoderegister();
	const [isSSR] = useState(!process.browser); // !process.browser
	const storage = isSSR === false ? window.localStorage.getItem('loader') : null;
	const localData = storage ? JSON.parse(storage) : {};

	// Set loaders
	const loaders = [
		{
			name: 'properties',
			enabled: load.properties || false
		},
		{
			name: 'config',
			enabled: load.config || false
		},
		{
			name: 'language',
			enabled: load.language || false
		},
		{
			name: 'coderegister',
			enabled: load.coderegister || false
		}
	];

	// Get all last modification timestampes for each section, which is already in cache
	const modified = {};
	loaders.map((row) => {
		if (row.enabled && localData[row.name]) {
			// Config & languages
			if (localData[row.name].last_modified) {
				modified[row.name] = localData[row.name].last_modified;
			}

			// Coderegisters
			if (localData[row.name].checksums) {
				modified[row.name] = localData[row.name].checksums;
			}
		}
		return true;
	});

	const { loading, data } = useQuery(request.gql.schema, { // error
		variables: {
			// Last modified records in local storage, to optimize load
			modified: JSON.stringify(modified),

			// Define, what to load
			properties: load.properties || false,
			config: load.config || false,
			language: load.language || false,
			coderegister: load.coderegister || false
		},
		context: setContext('loader'),
		skip: isSSR === true
	});

	const handleCache = (l) => {
		const loader = l;

		loaders.map((row) => {
			if (row.enabled) {
				if (row.name === 'properties') {
					if (isSSR === false && loader.properties) {
						window.localStorage.setItem('properties', loader.properties);
						appDispatch({ type: 'SET_PROPERTIES', payload: JSON.parse(loader.properties) });
					}
				} else {
					// Convert string to object
					loader[row.name] = typeof loader[row.name] !== 'object' ? JSON.parse(loader[row.name]) : loader[row.name];

					// Check, if anything changed on server
					// console.log('Server data', loader[row.name]);

					// Continue only, if changed and not empty (client) OR if SSR
					if (isSSR === true || (loader[row.name].changed && !isEmptyObject(loader[row.name]))) {
						if (!localData[row.name]) {
							localData[row.name] = {};
						}

						// Load data from server & cache in local storage
						switch (row.name) {
						default: {
							localData[row.name] = {
								name: row.name,
								last_modified: loader[row.name].last_modified,
								data: loader[row.name].data
							};

							break;
						}

						case 'coderegister': {
							if (loader[row.name].data) {
								localData[row.name] = updateLocalCoderegisters(loader[row.name], false);
							}

							break;
						}
						}

						// console.log(`${row.name} (RE)LOADED!`);
					}
				}
			}

			return true;
		});

		// Save last settings & data to local storage
		if (localData) {
			// Save to local storage (if not SSR) - so client can use cached version
			if (isSSR === false) {
				window.localStorage.setItem('loader', JSON.stringify(localData));
			}

			Object.keys(localData).map((name) => {
				if (load[name] === true) {
					// console.log('UPDATE STATE FOR', name);

					return loaderDispatch({
						type: 'SET_LOADER_DATA',
						payload: {
							name,
							data: localData[name].data || {}
						}
					});
				}

				return true;
			});

			onLoad(true);
		}
	};

	// SSR handle
	// If server side rendering - read otherwise cached data in localhost from cached js file (object)
	if (isSSR === true) {
		// console.log('SSR / CACHED FROM FILE');
		handleCache(require('../../cache/loader.js').default);
	}

	// Client handle
	useEffect(() => {
		if (!loading && data && data.request) {
			// console.log('CLIENT / CACHED IN LOCAL STORAGE');
			handleCache(data.request);
		}
	}, [data]);

	// Handle auth
	useEffect(() => {
		// Initial load with cached data - if exist - then request is sent to server and update, if any changeds in config, language and/or coderegisters
		if (localData) {
			Object.keys(localData).map((name) => {
				if (localData[name] && localData[name].data) {
					return loaderDispatch({
						type: 'SET_LOADER_DATA',
						payload: {
							name,
							data: localData[name].data || {}
						}
					});
				}

				return true;
			});
		}
	}, []);

	return null;
};

AppLoader.defaultProps = {
	children: null,
	load: {}
};

AppLoader.propTypes = {
	children: PropTypes.node,
	onLoad: PropTypes.func.isRequired,
	load: PropTypes.shape({})
};

export default withApollo({ ssr: true })(AppLoader);
