import gql from 'graphql-tag';

const request = {
	properties: {
		name: 'loader'
	},

	data: null,

	gql: {
		schema: gql`
			query Loader(
				$platform: String,
				$properties: Boolean,
				$modified: String,
				$config: Boolean,
				$language: Boolean,
				$coderegister: Boolean
			) {
				request(
					platform: $platform,
					properties: $properties,
					modified: $modified,
					config: $config,
					language: $language,
					coderegister: $coderegister
				) {
					properties
					config
					language
					coderegister
				}
			}
		`
	}
};

export default request;
