// import { useState } from 'react';

import requestSessionCreateId from '../../requests/query/session/generateid';
import useAPI from './useAPI';

const useSession = () => {
	const { mutate } = useAPI();

	const generateId = (callback) => {
		mutate(requestSessionCreateId, {}, callback);
	};

	return { generateId };
};

export default useSession;
