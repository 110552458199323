import { useEffect } from 'react';

import useNotification from '../_hooks/useNotification';
import useAuth from '../_hooks/useAuth';

const StateLoader = () => {
	const { authenticated, login, logoutIfTokenInvalid } = useAuth();

	// == Check user auth status (auto login)

	useEffect(() => login(), []);

	// == Check user auth status (auto login) END

	// == Notifications (unread, header)

	const { loadNotifications, setUnreadList } = useNotification();

	useEffect(() => {
		if (authenticated) {
			loadNotifications({ isread: 0 }, (d, e) => {
				if (d) setUnreadList(d ? d.list : []);
				if (e) logoutIfTokenInvalid(e);
			});
		}
	}, [authenticated]);

	// == Notifications (unread, header) END

	return null;
};

export default StateLoader;
