import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

import reducer from '../reducers/message';

const MessageContext = createContext();
export default MessageContext;

export function MessageProvider({ children }) {
	const [data, dispatch] = useReducer(reducer, {
		socket: null,
		socketId: null
	});

	return (<MessageContext.Provider value={{ data, dispatch }}>{children}</MessageContext.Provider>);
}

MessageProvider.propTypes = {
	children: PropTypes.node.isRequired
};

export const useStore = () => useContext(MessageContext);
