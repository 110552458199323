import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';

import useAuth from '../_hooks/useAuth';
import useJWT from '../_hooks/useJWT';

const StateWatcher = () => {
	const router = useRouter();
	const { events, asPath } = router;

	// == Page change watcher
	const { logout } = useAuth();
	const { isJwtTokenValidClient, isJwtTokenValidServer } = useJWT();
	const [lastRoute, setLastRoute] = useState(asPath); // default = home

	const handleRouteChange = (route) => {
		if (route !== lastRoute) setLastRoute(route);
	};

	useEffect(() => {
		// Client token check
		const jwtCheck = isJwtTokenValidClient();
		if (jwtCheck === false) logout();

		// Server token check
		if (jwtCheck === true) {
			isJwtTokenValidServer((valid) => {
				if (!valid) logout();
			});
		}

		// if (jwtCheck === null) console.log('token not exist / user is not authorized!');
		// else console.log('token is valid');
	}, [lastRoute]);

	useEffect(() => {
		events.on('routeChangeComplete', handleRouteChange);

		return () => {
			events.off('routeChangeComplete', handleRouteChange);
		};
	}, [events]);

	// == Page change watcher END

	return null;
};

export default StateWatcher;
