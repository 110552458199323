import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import '../styles/main.scss';
// import { pwaTrackingListeners } from '../pwa/event-listeners';
import AppLoader from '../components/_system/app-loader';
import StateLoader from '../components/_system/state-loader';
import StateWatcher from '../components/_system/state-watcher';
import SystemMessages from '../components/_system/messages';
// import Lockdown from '../components/_system/lockdown';
import LoaderLine from '../components/loader/loader-line';
import Providers from '../contexts/providers';
import useSession from '../components/_hooks/useSession';

const MyApp = ({ Component, pageProps }) => {
	const [loadedCnt, incLoadedCnt] = useState(0);

	// const [isUnlocked] = useState(isBrowser && localStorage.getItem('lockdown'));

	/*
	if (isBrowser) {
		pwaTrackingListeners();
	}
	*/

	const [isBrowser] = useState(typeof window !== 'undefined');

	// Detect browsers/os
	const iOS = () => {
		if (isBrowser && window.navigator) {
			return [
				'iPad Simulator',
				'iPhone Simulator',
				'iPod Simulator',
				'iPad',
				'iPhone',
				'iPod'
			].includes(window.navigator.platform) ||
			// iPad on iOS 13 detection
			(window.navigator.userAgent.includes('Mac') && 'ontouchend' in document);
		}

		return false;
	};

	const [isiOS, setIsiOS] = useState(false);
	const [isSafari, setIsSafari] = useState(false);

		// Create possess id and save it to localstorage, if not exist yet
	const { generateId } = useSession();

	if (isBrowser && !window.localStorage.getItem('possessid')) {
		generateId((d, e) => {
			if (d) window.localStorage.setItem('possessid', d.possessid);
		});
	}

	// Clear html/md pages cache on refresh
	useEffect(() => {
		if (window.localStorage.getItem('content_pages') !== null) {
			window.localStorage.removeItem('content_pages');
		}
	}, []);

	// Calculate 1vh real height (and recalculate on scroll/resize)
	const handleResize = () => {
		// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
		// Then we set the value in the --vh custom property to the root of the document
		document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
	};

	useEffect(() => {
		if (isBrowser) {
			handleResize();
			window.addEventListener('resize', handleResize);

			// Check, if safari and/or iOS
			setIsiOS(iOS());
			setIsSafari(navigator.vendor.match(/apple/i));

			return () => window.removeEventListener('resize', () => handleResize);
		}

		return false;
	}, []);

	// enabled={isUnlocked === null}
	// <AppLoader onLoad={() => incLoadedCnt(loadedCnt + 1)} load={{ config: true }} />
	// IF ENABLED BACK - CHANGE reducer/loader.js  loadedCnt >= 3 + change here loadedCnt < 3
	/*
	return (
		<Providers>
			<Lockdown enabled={true}>
				<StateLoader />
				<SystemMessages />
				<div className={`${isSafari ? 'no-webp-img' : 'all-img'}${isiOS ? ' ios' : ''}`}>
					<AppLoader onLoad={() => incLoadedCnt(loadedCnt + 1)} load={{ properties: true, language: true }} />
					<AppLoader onLoad={() => incLoadedCnt(loadedCnt + 1)} load={{ coderegister: true }} />
					{ loadedCnt < 2 && <LoaderLine /> }
					<Component {...pageProps} />
				</div>
			</Lockdown>
		</Providers>
	);
	*/

	return (
		<Providers>
			<StateLoader />
			<StateWatcher />
			<SystemMessages />
			<div className={`${isSafari ? 'no-webp-img' : 'all-img'}${isiOS ? ' ios' : ''}`}>
				<AppLoader onLoad={() => incLoadedCnt(loadedCnt + 1)} load={{ properties: true, language: true }} />
				<AppLoader onLoad={() => incLoadedCnt(loadedCnt + 1)} load={{ coderegister: true }} />
				{ loadedCnt < 2 && <LoaderLine /> }
				<Component {...pageProps} />
			</div>
		</Providers>
	);
};

MyApp.propTypes = {
	// Component: PropTypes.func.isRequired,
	Component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
  ]).isRequired,
	pageProps: PropTypes.shape({}).isRequired
};

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
// MyApp.getInitialProps = async (appContext) => {
//   // calls page's `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(appContext);
//
//   return { ...appProps }
// }

export default MyApp;
