import React from 'react';
import PropTypes from 'prop-types';
import { CookiesProvider } from 'react-cookie';

import { LoaderProvider } from './loader';
import { AppProvider } from './app';
import { AuctionProvider } from './auction';
import { AuthProvider } from './auth';
import { UserProvider } from './user';
import { FilterProvider } from './filter';
import { ProfileProvider } from './profile';
import { MessageProvider } from './message';
import { HistoryProvider } from './history';

const Providers = ({ children }) => {
	return (
		<LoaderProvider>
			<CookiesProvider>
				<AppProvider>
					<HistoryProvider>
						<MessageProvider>
							<AuthProvider>
								<UserProvider>
									<ProfileProvider>
										<AuctionProvider>
											<FilterProvider>
												{children}
											</FilterProvider>
										</AuctionProvider>
									</ProfileProvider>
								</UserProvider>
							</AuthProvider>
						</MessageProvider>
					</HistoryProvider>
				</AppProvider>
			</CookiesProvider>
		</LoaderProvider>
	);
};

Providers.propTypes = {
	children: PropTypes.node.isRequired
};

export default Providers;
